import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedIcon } from '@components/icons';
import { socialMedia } from '@config';
import styled from 'styled-components';
import { theme, mixins, media } from '@styles';
const { colors, fontSizes, fonts } = theme;

const StyledContainer = styled.footer`
  ${mixins.flexCenter};
  flex-direction: column;
  padding: 15px;
  text-align: center;
  height: auto;
  min-height: 70px;
`;
const StyledSocial = styled.div`
  color: ${colors.lightSlate};
  width: 100%;
  max-width: 270px;
  margin: 0 auto 10px;
  display: none;
  ${media.tablet`display: block;`};
`;
const StyledSocialList = styled.ul`
  ${mixins.flexBetween};
  padding: 0;
  margin: 0;
  list-style: none;
`;
const StyledSocialLink = styled.a`
  padding: 10px;
  svg {
    width: 20px;
    height: 20px;
  }
`;
const StyledMetadata = styled.div`
  font-family: ${fonts.SFMono};
  font-size: ${fontSizes.xs};
  line-height: 1;
`;
const StyledGitHubLink = styled.a`
  color: ${colors.lightSlate};
  padding: 10px;
`;
const StyledFooterLink = styled.a`
  color: ${colors.lightSlate};
  padding: 10px;
  margin-top: -10px;
`;

// const StyledGitHubInfo = styled.div`
//   margin-top: 10px;

//   & > span {
//     display: inline-flex;
//     align-items: center;
//     margin: 0 7px;
//   }
//   svg {
//     display: inline-block;
//     height: 15px;
//     width: auto;
//     margin-right: 5px;
//   }
// `;

const Footer = () => {
  // const [githubInfo, setGitHubInfo] = useState({
  //   stars: null,
  //   forks: null,
  // });

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      return;
    }
    // fetch('https://api.github.com/repos/bchiang7/v4')
    //   .then(response => response.json())
    //   .then(json => {
    //     const { stargazers_count, forks_count } = json;
    //     setGitHubInfo({
    //       stars: stargazers_count,
    //       forks: forks_count,
    //     });
    //   })
    //   .catch(e => console.error(e));
  }, []);

  return (
    <StyledContainer>
      <StyledSocial>
        <StyledSocialList>
          {socialMedia &&
            socialMedia.map(({ name, url }, i) => (
              <li key={i}>
                <StyledSocialLink
                  href={url}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  aria-label={name}>
                  <FormattedIcon name={name} />
                </StyledSocialLink>
              </li>
            ))}
        </StyledSocialList>
      </StyledSocial>
      <StyledMetadata tabindex="-1">
        <StyledGitHubLink
          href="https://brittanychiang.com"
          target="_blank"
          rel="nofollow noopener noreferrer">
          <div>Designed by Brittany Chiang</div>

          {/* {githubInfo.stars && githubInfo.forks && (
            <StyledGitHubInfo>
              <span>
                <FormattedIcon name="Star" />
                <span>{githubInfo.stars.toLocaleString()}</span>
              </span>
              <span>
                <FormattedIcon name="Fork" />
                <span>{githubInfo.forks.toLocaleString()}</span>
              </span>
            </StyledGitHubInfo>
          )} */}
        </StyledGitHubLink>
        <br />
        <StyledFooterLink
          href="https://linkedin.com/in/dakshkhetan"
          target="_blank"
          rel="nofollow noopener noreferrer">
          <div>Revised by Daksh Khetan</div>
        </StyledFooterLink>
      </StyledMetadata>
    </StyledContainer>
  );
};

Footer.propTypes = {
  githubInfo: PropTypes.object,
};

export default Footer;
