module.exports = {
  siteTitle: 'Daksh Khetan',
  siteDescription:
    'Daksh Khetan is a Full Stack Developer based in Dallas, TX, United States who enjoys creating things that live on the internet, whether that be websites or applications.',
  siteKeywords:
    'Daksh Khetan, Daksh, Khetan, dakshkhetan, dakshk, software engineer, full stack developer, web developer, javascript, utd, utdallas, the university of texas at dallas, ms cs, computer science, notion, data science, Dallas, big howdy, whoosh, comet, coderoom, coding ninjas, indian, daksh bvp, daksh bvcoe, acm, acm bvp, acm bvcoe, bvcoe, bvp',
  siteUrl: 'https://dakshkhetan.now.sh',
  siteLanguage: 'en_US',
  googleAnalyticsID: 'UA-172930109-1',
  googleVerification: 'USA5XoxN1Z5gQJGHGBOA4cWs320qDegbzaaXh-AZBpE',
  name: 'Daksh Khetan',
  location: 'Dallas, TX, United States',
  email: '2012daksh@gmail.com',
  github: 'https://github.com/dakshkhetan',
  twitterHandle: '@dakshkhetan',
  socialMedia: [
    {
      name: 'GitHub',
      url: 'https://github.com/dakshkhetan',
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/dakshkhetan',
    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/dakshkhetan',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/dakshkhetan',
    },
  ],

  navLinks: [
    {
      name: 'About',
      url: '/#about',
    },
    {
      name: 'Experience',
      url: '/#jobs',
    },
    {
      name: 'Work',
      url: '/#projects',
    },
    {
      name: 'Contact',
      url: '/#contact',
    },
  ],

  navHeight: 100,

  colors: {
    green: '#64ffda',
    navy: '#0a192f',
    darkNavy: '#020c1b',
  },

  srConfig: (delay = 200) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor: 0.25,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
